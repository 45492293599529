.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #15616d;
  padding-bottom: 10vh;
}

.about-section h2 {
  justify-content: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 3em;
}

.about-content {
  display: flex;
  width: 70%;
  flex-direction: column;
  font-size: 15px;
  font-weight: 400;
}

.personal-photo {
  max-width: 90%;
  margin: 0 auto 10% auto;
  box-shadow: 5px 5px 10px lightgrey, -5px -5px 10px lightgrey;
}

.about-description {
  max-width: 90%;
  margin: 0 auto;
}

.social-icon {
  height: 50px;
  width: 50px;
  margin: 0 2%;
}

.small-icon {
  height: 10px;
}

li {
  list-style: square;
}

@media screen and (min-width: 900px) {
  .about-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .personal-photo {
    object-fit: cover;
    width: 50%;
    max-height: 500px;
    margin: 0;
  }

  .about-description {
    align-self: center;
    width: 45%;
    margin: 0;
    font-size: 18px;
  }
}
