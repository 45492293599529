.project-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5% 0;
  background: linear-gradient(145deg, #166875, #135762);
  box-shadow: 5px 5px 10px lightgrey, -5px -5px 10px lightgrey;
}

@media screen and (min-width: 600px) {
  .project-card {
    flex-direction: row;
    margin: 2% 0;
  }
}

.project-image {
  max-height: 40%;
  object-fit: cover;
}

@media screen and (min-width: 600px) {
  .project-image {
    width: 40%;
    flex-shrink: 0;
  }
}

.project-content {
  padding: 5%;
}

@media screen and (min-width: 600px) {
  .project-content {
    padding: 2%;
  }
}

.project-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

@media screen and (min-width: 600px) {
  .project-header {
    flex-direction: row;
    align-items: center;
  }
}

.project-title {
  font-size: 1.5em;
  font-weight: 900;
  margin-right: 5px;
  margin-bottom: 0;
  color: #ffecd1;
  max-width: 100%;
}

.project-content p:nth-child(2) {
  color: #ffecd1;
}

.project-technologies {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.project-links {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.project-links a {
  display: flex;
  padding: 2px;
  color: black;
  border-radius: 9px;
}

.project-links a img {
  max-width: 2vw;
}
