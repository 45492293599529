.banner-section {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #001524;
}

hr {
  height: 1px;
  background: #ffecd1;
  border: none;
}

.banner-content h1 {
  font-size: 10vw;
}

.banner-content p {
  font-size: 4vw;
}

.banner-content hr {
  width: 50%;
  margin-bottom: 5%;
}

.blur {
  filter: blur(8px);
}
