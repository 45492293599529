.skills-section {
  background: #001524;
  min-height: 100vh;
  padding: 10vh 0;
  color: #ffecd1;
}

/* ============================================== */
/* Mobile */
/* ============================================== */

.skills-headline {
  text-align: center;
}

.skills-headline h2 {
  font-size: 3em;
}

.skills-headline p {
  font-size: 1.5em;
}

.skills-header {
  font-weight: 600;
  font-size: 1.5em;
  padding: 2vh 0;
  margin: 0;
}

.skills-display {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.skills-container {
  margin: auto auto;
  width: 90%;
}

/* ============================================== */
/* Desktop */
/* ============================================== */
@media screen and (min-width: 600px) {
  .skills-display {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .skills-container {
    width: 75%;
  }
}

@media screen and (min-width: 900px) {
  .skills-display {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
