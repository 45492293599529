/* =================================== */
/* Profile bar at top of page */
/* =================================== */

.profile-bar-top {
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: 1s ease-in-out;
}

.slide-to-center {
  transform: translate(42vw, 40vh) scale(3);
  z-index: 1;
  filter: blur(0px);
}

@media screen and (min-width: 600px) {
  .slide-to-center {
    transform: translate(calc(50vw - 100px), calc(50vh - 10px)) scale(3);
    z-index: 1;
  }
}

.profile-icon {
  height: 50px;
  width: 50px;
  margin: 5px;
}

.profile-icon:hover {
  border-radius: 50px;
}

.github-icon {
  filter: invert(100%);
}

@media screen and (min-width: 600px) {
  .profile-bar-top {
    flex-direction: row;
  }
}

/* =================================== */
/* Profile bar at bottom of page */
/* =================================== */

.profile-bar-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0;
}

@media screen and (min-width: 600px) {
  .profile-bar-bottom {
    position: absolute;
    margin-top: 0;
  }
}
