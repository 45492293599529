.skill-item {
  display: flex;
  flex-direction: row;
  background: linear-gradient(145deg, #166875, #135762);
  border-radius: 5px;
  overflow: hidden;
}

.skill-item-logo {
  height: 50px;
  width: 50px;
}

.skill-item-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #ffecd1;
  font-weight: 900;
  font-size: 1.25em;
}
