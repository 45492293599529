/* ====================================== */
/* Mobile */
/* ====================================== */

.projects-section {
  min-height: 100vh;
  padding-top: 10vh;
  color: #15616d;
}

.project-display {
  width: 95%;
  margin: 0 auto;
}

.projects-headline {
  text-align: center;
  font-size: 3em;
  padding-bottom: 5vh;
}

/* ====================================== */
/* Desktop */
/* ====================================== */

@media screen and (min-width: 600px) {
  .project-display {
    width: 75%;
  }
}
