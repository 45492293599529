.nav {
  float: left;
  width: 80%;
  position: absolute;
  top: 0;
  right: 0;
}

.nav-list {
  display: flex;
  justify-content: flex-end;
}

.nav-list li {
  display: inline;
  color: #ffecd1;
  font-weight: bold;
  padding: 0 2%;
}

/* nth-child(-n+3) selects the first three lis */
.nav-list li:nth-child(-n + 3) {
  border-right: 2px solid #ffecd1;
}

@media screen and (min-width: 600px) {
  .nav-list li:nth-child(-n + 3) {
    border-right: 3px solid #ffecd1;
  }
}

li a,
li a:visited {
  color: #ffecd1;
  font-size: 1.25rem;
}

li a:hover {
  color: #ff7d00;
}
